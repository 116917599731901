// import { getCommonParamsItemLabel } from '@/utils/common'

export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'name',
      label: 'user.resource-group-name'
    },
    {
      prop: 'description',
      label: 'user.resource-group-desc'
    },
    // {
    //   prop: 'status',
    //   label: 'general.status',
    //   formatter: (row) => {
    //     return getCommonParamsItemLabel(row, 'status')
    //   }
    // },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    },
    {
      label: 'common.app-operation',
      width: '450',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
