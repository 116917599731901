<template>
  <div class="applications">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="$t('general.router-resource-group')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @viewBtnClick="handleViewData"
      @newBtnClick="handleNewData"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #otherHandler="scope">
        <!-- 由于这里的编辑、删除按钮的控制条件和公共组件的不一样，为了公共组件的简洁性这里按钮重写 -->
        <el-button
          class="opt-btn"
          v-if="isUpdate && !scope.row.isSystem && !isSelfGroup(scope.row.id)"
          icon="el-icon-edit"
          size="mini"
          type="primary"
          plain
          @click="handleEditData(scope.row.id)"
          >{{ $t('general.edit') }}</el-button
        >
        <el-button
          class="opt-btn"
          v-if="isDelete && !scope.row.isSystem && !isSelfGroup(scope.row.id)"
          icon="el-icon-delete"
          size="mini"
          type="danger"
          plain
          @click="handleDeleteClick(scope.row)"
          >{{ $t('general.delete') }}</el-button
        >
      </template></page-content
    >
  </div>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from 'vue'
import i18n from '@/i18n'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useStore } from 'vuex'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'

import { searchFormConfig } from './config/resouce.search'
import { contentTableConfig } from './config/resouce.table'

import { useRouter } from 'vue-router'
import { usePermission } from '@/hooks/use-permission'
import { getItem } from '@/utils/storage'

// 表格基础配置项
const tableOptions = {
  moduleName: 'user', // 所属的模块
  pageName: 'resources', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowCreateSlot: true, // 是否显示添加按钮的插槽
  isShowDeleteBtn: false,
  isShowEditBtn: false,
  pageAction: 'user/getResourceGroupList', // 获取表格的Action
  pageCountGetter: 'user/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'user/pageListData' // 获取表格列表数据getters
}

const tableOptionsConfig = computed(() => tableOptions)
const isUpdate = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'edit'
)
const isDelete = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'delete'
)
const pageContentRef = ref('')
const pageSearchRef = ref('')
const store = useStore()
const router = useRouter()

const { t } = i18n.global
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

// 获取所有资源菜单
store.dispatch('user/getResourceMenuBtnList')

const userInfo = getItem('userInfo') || {}

const isSelfGroup = computed(() => {
  return (group) => {
    const groupList = userInfo.resourceGroupId
      ? userInfo.resourceGroupId.split(',')
      : []
    return groupList.includes(group)
  }
})

const handleQueryClick = (queryInfo) => {
  // 调用page-search中的getPageData方法
  pageContentRef.value.getPageData(queryInfo)
}
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}
const handleViewData = (id) => {
  router.push(`/groups/view/${id}`)
}

const handleEditData = (id) => {
  router.push(`/groups/edit/${id}`)
}

const handleNewData = () => {
  router.push(`/groups/new`)
}

const handleDeleteClick = (item) => {
  ElMessageBox.confirm(
    t('popup.global-delete-tip'),
    t('popup.confirm-warning-title'),
    {
      confirmButtonText: t('popup.confirm-ok-btn'),
      cancelButtonText: t('popup.confirm-cancel-btn'),
      type: 'warning'
    }
  )
    .then(async () => {
      handleMessageCommit('user/handleDeleteResourceAction', item.id).then(
        () => {
          const formData = pageSearchRef.value.getCurrentFieldValues()
          pageContentRef.value.getPageData(formData)
        }
      )
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: t('popup.cancel-delete-tip')
      })
    })
}
const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formData)
}
</script>
